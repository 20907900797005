<template>
    <div>
        <page-title :heading="$t('accounting.lang_viewInvoices')"  show-previous-button :subheading=subheading :icon=icon :url-previous="oldRoute"></page-title>
        <div class="app-main__inner">
                        <DisplayInvoice></DisplayInvoice>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import DisplayInvoice from "../../components/accounting/DisplayInvoice";

    export default {
        components: {
            PageTitle,
            DisplayInvoice
        },
        props: ["id"],
        data: () => ({
            subheading: '',
            icon: 'pe-7s-search icon-gradient bg-tempting-azure',
            oldRoute:null,
        }),
      beforeRouteEnter(to,from,next){
        next(vm => {
          vm.oldRoute = from.fullPath;
          next();
        });
      }
    }
</script>